import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
import { InlineWidget } from "react-calendly";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>
                        Us presentem la preparació per a l'entrevista de Mossos d'Esquadra 2025
                    </strong>
                </p>

                <p>
                    A iOpos Acadèmia comptem amb una metodologia pròpia de preparació d'entrevistes
                    que ens brinda excel·lents resultats any rere any.
                </p>

                <p>
                    La nostra preparació es basa en l'entrevista oficial i compta amb els millors
                    preparadors.
                </p>

                <p>
                    <strong>Duració:</strong> 1 hora i 30 minuts, amb un enfocament totalment
                    personalitzat.
                </p>

                <p>
                    Cada alumne és analitzat individualment, sense preguntes estàndard. La
                    preparació s'adapta de manera natural a la seva personalitat i forma de ser.
                </p>

                <p>
                    En els darrers anys, hem mantingut el millor percentatge d'aprovats, incloent-hi
                    els números 1.
                </p>

                <p>
                    <strong>La preparació inclou:</strong>
                </p>

                <ul>
                    <li>a.) Anàlisi de l'aspirant.</li>
                    <li>b.) Estructura del currículum.</li>
                    <li>c.) Possibles preguntes oficials.</li>
                    <li>d.) Aspectes a destacar i autoconeixement personal.</li>
                    <li>5.) Motivacions dels canvis laborals i objectius aconseguits.</li>
                    <li>6.) Tancament de l'entrevista.</li>
                </ul>

                <p>
                    <strong>Important:</strong> És imprescindible portar el vostre currículum, ja
                    que serà la vostra carta de presentació.
                </p>

                <p>Un any més, la nostra preparació és encara més personalitzada.</p>

                <div className="mt-20">
                    <h1 className="font-bold font-montserrat text-xl text-cpurple">
                        Entrevistes disponibles per Formador
                    </h1>
                    <div id="disponibles" />
                    <div className="grid grid-cols-1 gap-10 mt-10">
                        <div className="rounded-md shadow-xl p-5 border border-gray-400">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formador Jordi (Psicòleg) - C/ Martí Codolar, 18 - Hospitalet de
                                Llobregat
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat mt-5">
                                Si no veus correctament el calendari fes clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/iopos-academia-jordi/preparacio-dentrevista-policial-1-30-h"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                // className="-mt-10"
                                url="https://calendly.com/iopos-academia-jordi/preparacio-dentrevista-policial-1-30-h?hide_gdpr_banner=1"
                            />
                        </div>

                        <div className="rounded-md shadow-xl p-5 border border-gray-400">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formador Rubén (Comandament de Mossos) - Carrer de les Travesseres,
                                núm. 43, Granollers (COWORKING)
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat mt-5">
                                Si no veus correctament el calendari fes clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/iopos-academia-ruben/preparacio-dentrevista-policial-1-30-h"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                // className="-mt-10"
                                url="https://calendly.com/iopos-academia-ruben/preparacio-dentrevista-policial-1-30-h?hide_gdpr_banner=1"
                            />
                        </div>

                        {/*<div className="rounded-md shadow-xl p-5 border border-gray-400">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formadora Sofia - C/ Martí Codolar, 18 - Hospitalet de Llobregat
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat mt-5">
                                Si no veus correctament el calendari fes clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/iopos-academia-sofia/preparacion-d-entrevista-policial-1-30-h"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                // className="-mt-10"
                                url="https://calendly.com/iopos-academia-sofia/preparacion-d-entrevista-policial-1-30-h?hide_gdpr_banner=1"
                            />
                        </div>*/}

                        <div className="rounded-md shadow-xl p-5 border border-gray-400">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formador Txema (Agent CME, cursant grau en psicologia) - C/ Martí
                                Codolar, 18 - Hospitalet de Llobregat
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat mt-5">
                                Si no veus correctament el calendari fes clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/iopos-academia-jose-m/preparacio-dentrevista-policial-1-30-h"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                // className="-mt-10"
                                url="https://calendly.com/iopos-academia-jose-m/preparacio-dentrevista-policial-1-30-h?hide_gdpr_banner=1"
                            />
                        </div>
                    </div>
                </div>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/WlSCeOhwo1A"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Destaquem el millor de tu per superar l'entrevista amb èxit."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
